.columnSelectMore {
  padding-top: 0 !important;
  padding-bottom: 3px !important;
}

.selectedSummary {
  font-size: 12px;
}

.visibilityButton {
  background: white !important;
  height: 26px !important;
  margin-left: -4px;
}

.tableFieldSelect {
  .buttonLabel {
    font-size: 1rem;
  }
  .optionLabel {
    font-size: 13px;
  }
}
