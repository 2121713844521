:root,
[data-bs-theme="light"] {
  --color-background: hsl(0, 0%, 90.98%); /* #e8e8e8; */
  --color-background-medium: hsl(0, 0%, 94.5%);
  --color-background-light: hsl(0, 0%, 99%);

  --color-goldwood: hsl(38.3, 40.17%, 54.12%);
  --color-goldwood-light: hsl(38.3, 40.17%, 85%);
  --color-linen: hsl(35, 13.04%, 81.96%);
  --color-linen-white: hsl(35, 13.04%, 97%);
  --color-linen-light: hsl(35, 13.04%, 92%);
  --color-linen-dark: hsl(35, 13.04%, 75%);
  --color-charcoal: hsl(30, 7.02%, 22.35%);
  --color-skyfall: #71b2c9;
  --color-skyfall-medium: hsl(195.68, 44.9%, 40%);
  --color-skyfall-dark: hsl(195.68, 44.9%, 50%);
  --color-skyfall-light: hsl(195.68, 44.9%, 85%);
  --color-skyfall-white: hsl(195.68, 44.9%, 95%);
  --color-walnut: #8b5b29;

  --color-aro-green: #81b234;
  --color-aro-yellow: hsl(48.9, 98.04%, 60%); /* #fdd835;*/
  --color-aro-yellow-dark: hsl(48.9, 98.04%, 45%);
  --color-aro-red: #da2836;
  --color-aro-gray: #b8c3c7;

  --bs-primary-rgb: var(--color-charcoal);
  --bs-box-shadow: 0 0.5rem 1rem rgba(42, 21, 21, 0.5);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.2);

  --toastify-color-success: var(--color-aro-green);
}
/*
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;
*/

body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  color: var(--color-charcoal);
}

h1,
h2 {
  color: var(--color-charcoal);
}

/* Better/Proper way to do this is with SASS and mixins... */
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: var(--color-skyfall) !important;
  border: none;
}

.btn-primary:hover,
.btn-primary:active {
  transform: scale(1.01);
}

.btn-primary:disabled {
  background-color: var(--color-aro-gray) !important;
  border: none;
}
.nav-link {
  color: var(--color-walnut);
  font-weight: 500;
}

.nav-link:hover {
  color: var(--color-goldwood);
  font-weight: 600;
}

input:focus-visible {
  outline: 1px solid var(--color-charcoal);
  border-radius: 2px;
}

.aro-checkbox .form-check-input:checked {
  background-color: var(--color-skyfall-dark);
  border-color: var(--color-skyfall-dark);
}

.form-check-input:focus {
  border-color: var(--color-skyfall);
  outline: 0;
  box-shadow: 0 0 0 0.05 rem var(--color-skyfall);
}

.dropdown-menu {
  width: max-content;
}
