.scoutProject {
  .projectSelectContainer {
    min-width: 300px;
  }
  .cardsSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    .doubleRow {
      grid-row: span 2;
    }
  }
  .projectCardContainer {
    background: white;
    border-radius: 4px;
    padding: 24px 20px;

    .cardTitle {
      font-size: 1.1rem;
      color: var(--color-skyfall-medium);
      font-weight: 700;
    }
    .fieldLabel {
      font-size: 1rem;
      color: var(--color-skyfall-medium);
      font-weight: 500;
      margin-bottom: 0;
    }
    .ntaVersionContainer {
      min-height: 35px;
    }
    .rosterSelectContainer {
      min-width: 60%;
      max-width: 60%;
    }
  }

  @media (max-width: 1000px) {
    .cardsSection {
      display: flex;
      flex-direction: column;
      .doubleRow {
        grid-row: unset;
        height: 200px;
      }
    }
  }
  @media (max-width: 400px) {
    .projectCardContainer {
      width: 100%;
      margin: auto;
    }
  }
}
